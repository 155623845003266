import { Button, Card } from "react-bootstrap";
import "./Courses.css";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

interface Course {
  weekday: string;
  date: string;
  skip?: string;
  time: string;
  description: string;
  cost: string;
}

const courses: Course[] = [
  {
    weekday: "Donnerstags",
    date: "vom 06.06.2024 bis zum 22.08.2024",
    skip: "(Im Juli finden keine Kurse statt)",
    time: "11:15 - 12.45 Uhr",
    description: "Für Kinder die im März und April 2024 geboren sind.",
    cost: "160€ (für 8 Treffen je 90 Minuten)",
  },
  {
    weekday: "Freitags",
    date: "vom 13.09.2024 bis zum 01.11.2024",
    time: "09:00 - 10.30 Uhr",
    description: "Für Kinder die im Mai, Juni und Juli 2024 geboren sind.",
    cost: "160€ (für 8 Treffen je 90 Minuten)",
  },
  {
    weekday: "Freitags",
    date: "vom 01.11.2024 bis zum 20.12.2024",
    time: "11:00 - 12.30 Uhr",
    description: "Für Kinder die im August und September 2024 geboren sind.",
    cost: "160€ (für 8 Treffen je 90 Minuten)",
  },
];

export const Courses = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<Course | undefined>();

  return (
    <div className="course-container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Herzens Baby - PEKiP® Kurse in Weyhe - Kurse</title>
        <link rel="canonical" href="https://herzens-baby.de/kurse" />
        <meta
          name="description"
          content="Hier findet ihr alle PEKiP® (Prager-Eltern-Kind-Programm) Kurse die in den nächsten Monaten starten. Ihr könnt euch auch direkt für einen Kurs eurer Wahl anmelden."
        />
      </Helmet>
      {selectedCourse === undefined && (
        <div className="courses">
          {courses.map((c) => (
            <CourseCard
              key={c.date}
              course={c}
              setSelectedCourse={() => setSelectedCourse(c)}
            />
          ))}
        </div>
      )}
      {selectedCourse !== undefined && !success && (
        <SignUp
          course={selectedCourse}
          success={() => setSuccess(true)}
          cancel={() => setSelectedCourse(undefined)}
        />
      )}

      {selectedCourse !== undefined && success && (
        <SuccessMessage
          course={selectedCourse}
          cancel={() => {
            setSelectedCourse(undefined);
            setSuccess(false);
          }}
        />
      )}
    </div>
  );
};

export interface CourseCardProps {
  course: Course;
  setSelectedCourse: () => void;
}

export const CourseCard = (props: CourseCardProps) => {
  return (
    <Card className="course-card">
      <h3 className="header">PEKiP® Kurs</h3>
      <img className="logo" src="/img/icons/pekip.svg" alt="pekip" />

      <div className="course-part when">
        <h5 className="label">Wann?</h5>
        <div className="values">
          <p>
            {props.course.weekday}, {props.course.time}
            <br />
            {props.course.date}
            <br />
            {props.course.skip !== undefined && (
              <>
                {props.course.skip}
                <br />
              </>
            )}
          </p>
        </div>
      </div>

      <div className="course-part who">
        <h5 className="label">Für Wen?</h5>
        <div className="values">
          <p className="description">{props.course.description}</p>
        </div>
      </div>

      <div className="course-part where">
        <h5 className="label">Wo?</h5>
        <div className="values">
          <a
            className="contact-part-text"
            href={
              "https://www.google.com/maps/place/Hebammenpraxis+Weyhe/@52.9825324,8.8465135,19z/data=!3m2!4b1!5s0x47b0d9846f90ab23:0x9e6283c2075bd895!4m6!3m5!1s0x47b127745a00d03f:0x9298b437bb6fe940!8m2!3d52.9825316!4d8.8471572!16s%2Fg%2F11fz9zyy09?entry=ttu"
            }
            target="_blank"
            rel="noreferrer"
          >
            Hebammenpraxis Weyhe
            <br />
            Bahnhofsstr. 4
            <br />
            28844 Weyhe
          </a>
          <p></p>
        </div>
      </div>

      <div className="course-part how-much">
        <h5 className="label">Preis:</h5>
        <div className="values">
          <p className="description">{props.course.cost}</p>
        </div>
      </div>

      <div className="sign">
        <Button onClick={props.setSelectedCourse}>Anmelden</Button>
      </div>
    </Card>
  );
};

export interface SignUpProps {
  course: Course;
  success: () => void;
  cancel: () => void;
}

export const SignUp = (props: SignUpProps) => {
  const [childFirstName, setChildFirstName] = useState<string>("");
  const [childLastName, setChildLastName] = useState<string>("");
  const [childET, setChildEt] = useState<string>("");
  const [childBirthday, setChildBirthday] = useState<string>("");
  const [specialNeeds, setSpecialNeeds] = useState<string>("");

  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [zipCode, setZipCode] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const [agb, setAgb] = useState<boolean>(false);
  const [dataSafty, setDataSafty] = useState<boolean>(false);

  const [validMail, setValidMail] = useState<boolean>(false);
  const [canSign, setCanSign] = useState<boolean>(false);

  useEffect(() => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const re = new RegExp(emailRegex);
    setValidMail(re.test(email?.toLowerCase() ?? ""));
  }, [email]);

  useEffect(() => {
    setCanSign(
      childFirstName !== "" &&
        childLastName !== "" &&
        childET !== "" &&
        childBirthday !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        street !== "" &&
        zipCode !== "" &&
        city !== "" &&
        validMail &&
        agb &&
        dataSafty
    );
  }, [
    childFirstName,
    childLastName,
    childET,
    childBirthday,
    firstName,
    lastName,
    street,
    zipCode,
    city,
    validMail,
    agb,
    dataSafty,
  ]);

  function handleSignUp() {
    const mail = {
      subject: `Anmeldung: PEKiP® Kurs ${props.course.date} - ${firstName} ${lastName}`,
      body: `
        <h3>Kursanmeldung über die Homepage:</h3>

        <h2>Angaben zum Kind</h2>

        <table class="tab">
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Vorname:</td>
            <td style="padding:0px 20px; margin:0px;">${childFirstName}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Nachname:</td>
            <td style="padding:0px 20px; margin:0px;">${childLastName}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">ET:</td>
            <td style="padding:0px 20px; margin:0px;">${childET}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Geburtstag:</td>
            <td style="padding:0px 20px; margin:0px;">${childBirthday}</td>
          </tr>
        </table>

        <h4>Besonderheiten:</h4>
        <p>${specialNeeds}</p>

        <table class="tab">
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Vorname:</td>
            <td style="padding:0px 20px; margin:0px;">${firstName}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Nachname:</td>
            <td style="padding:0px 20px; margin:0px;">${lastName}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Straße:</td>
            <td style="padding:0px 20px; margin:0px;">${street}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Postleitzahl:</td>
            <td style="padding:0px 20px; margin:0px;">${zipCode}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Stadt:</td>
            <td style="padding:0px 20px; margin:0px;">${city}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">E-Mail:</td>
            <td style="padding:0px 20px; margin:0px;">${email}</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Telefon:</td>
            <td style="padding:0px 20px; margin:0px;">${phone}</td>
          </tr>
        </table>

        <table class="tab">
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">Datenschutz akzeptiert:</td>
            <td style="padding:0px 20px; margin:0px;">Ja</td>
          </tr>
          <tr style="heigth:16px;">
            <td style="font-weight:bold;padding:0px; margin:0px;">AGB's akzeptiert:</td>
            <td style="padding:0px 20px; margin:0px;">Ja</td>
          </tr>
        </table>

        `,
      from: email,
      key: "7E1F4909-E4E6-4698-A14D-37FF3DBC707A",
      confirmationSubject: `Anmeldebestätigung für den PEKiP® Kurs ${props.course.date}`,
      confirmationBody: `
        <p>Hallo ${firstName},</p>

        <p>hiermit bestätige ich dir den Empfang deiner Anmeldung für den PEKiP® Kurs ${props.course.date}.</p>
        
        <p>Ich melde mich schnellstmöglich mit einer Anmeldebestätigung und weiteren Informationen bei dir.</p>

        <p>Liebe Grüße</p>
        <p>
        <strong>Denise von Ohlen</strong><br/>
        PEKiP® Gruppenleiterin
        </P>
      `,
    };
    fetch("https://mail.vonohlensoftware.de/mail", {
      method: "Post",
      headers: {
        "content-type": "application/json",
      },
      body: JSON.stringify(mail),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(response);
          alert("Error");
        } else {
          props.success();
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error");
      });
  }

  return (
    <div className="sign-up">
      <div className="course">
        <h2>Anmeldung für den PEKiP® Kurs {props.course.date}</h2>
      </div>

      <div className="section-header">
        <h2>Angaben zum Kind</h2>
      </div>

      <div className="info-field">
        <label className="label">Vorname*:</label>
        <input
          value={childFirstName}
          onChange={(e) => setChildFirstName(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Nachname*:</label>
        <input
          value={childLastName}
          onChange={(e) => setChildLastName(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Errechneter ET*:</label>
        <input
          value={childET}
          onChange={(e) => setChildEt(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Geburtsdatum*:</label>
        <input
          value={childBirthday}
          onChange={(e) => setChildBirthday(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Besonderheiten, die ich wissen muss:</label>
        <textarea
          className="input"
          value={specialNeeds}
          onChange={(e) => setSpecialNeeds(e.target.value)}
        ></textarea>
      </div>

      <div className="section-header">
        <h2>Angaben zum Elternteil / Erziehungsberechtigten</h2>
      </div>

      <div className="info-field">
        <label className="label">Vorname*:</label>
        <input
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Nachname*:</label>
        <input
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Straße*:</label>
        <input
          value={street}
          onChange={(e) => setStreet(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Postleitzahl*:</label>
        <input
          value={zipCode}
          onChange={(e) => setZipCode(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Stadt*:</label>
        <input value={city} onChange={(e) => setCity(e.target.value)}></input>
      </div>

      <div className="info-field">
        <label className="label">E-Mail*:</label>
        <input
          className={validMail ? "" : " invalid"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></input>
      </div>

      <div className="info-field">
        <label className="label">Telefon:</label>
        <input
          className="input"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        ></input>
      </div>

      <label className="accept-box">
        <input type="checkbox" checked={agb} onChange={() => setAgb(!agb)} />
        Hiermit akzeptiere ich die{" "}
        <a href="/agb" target="_blank" rel="noreferrer">
          AGB's
        </a>
      </label>

      <label className="accept-box">
        <input
          type="checkbox"
          checked={dataSafty}
          onChange={() => setDataSafty(!dataSafty)}
        />
        Hiermit akzeptiere ich die{" "}
        <a href="/datenschutz" target="_blank" rel="noreferrer">
          Datenschutzbedingungen
        </a>
      </label>

      <div className="notes">
        <p>* Pflichtangaben</p>
      </div>

      <div className="button">
        <Button disabled={!canSign} onClick={handleSignUp}>
          Verbindlich Anmelden
        </Button>
      </div>

      <div className="button">
        <Button onClick={props.cancel}>Abbrechen</Button>
      </div>
    </div>
  );
};

export interface SuccessMessageProps {
  course: Course;
  cancel: () => void;
}

export const SuccessMessage = (props: SuccessMessageProps) => {
  return (
    <div className="success-msg">
      <h3 className="header">Anmeldung wurde erfolgreich versandt!</h3>
      <img className="logo" src="/img/icons/pekip.svg" alt="pekip" />
      <div className="text">{`Deine Anmeldung für den PEKiP® Kurs ${props.course.date} wurde erfolgreich versandt. 
      Du solltest in den nächsten Minuten eine Empfangsbestätigung per Mail bekommen.`}</div>
      <div className="back">
        <Button onClick={props.cancel}>Zurück</Button>
      </div>
    </div>
  );
};
